import { __, __x, __nx, __N } from 'JIX/gettext.js';

import { getNumberFormatter } from 'JIX/utils.js';

const { sitename } = window.Stash.common;

export default {
    greeting: (name, timeOfDay) => {
        if (name) {
            if (timeOfDay === 'morning') {
                return __x('Godmorgen {name}!', { name });
            } else if (timeOfDay === 'evening') {
                return __x('Godaften {name}!', { name });
            } else {
                return __x('Hej {name}!', { name });
            }
        } else {
            if (timeOfDay === 'morning') {
                return __('Godmorgen!');
            } else if (timeOfDay === 'evening') {
                return __('Godaften!');
            } else {
                return __('Velkommen!');
            }
        }
    },
    numExposures: (n) =>
        __nx('{n} CV-visning', '{n} CV-visninger', n, { n: getNumberFormatter().format(n) }),
    cvStatsDesc: (days) =>
        __nx('CV-statistik ({days} dag)', 'CV-statistik ({days} dage)', days, { days }),
    addLatestJob: () =>
        __('Hvad er dit seneste job?'),
    jobHitcount: (hitcount) =>
        __nx('{n} jobannonce', '{n} jobannoncer', hitcount, { n: getNumberFormatter().format(hitcount) }),
    savedJobs: () =>
        __('Gemte job'),
    myjobPlaceholder: () =>
        __('Log ind for at se dine gemte job'),
    numSavedJobs: (n) =>
        __nx('{n} gemt job', '{n} gemte job', n, { n: getNumberFormatter().format(n) }),
    numSavedJobsNotApplied: (n) =>
        __nx('{n} ikke ansøgt', '{n} ikke ansøgt', n, { n: getNumberFormatter().format(n) }),
    editJobHistory: () =>
        __('Ret seneste job'),
    createCv: () =>
        __('Opret CV'),
    cvOnlineTipHeaderTag: () =>
        __('Dit CV er offline'),
    cvOnlineTipHeader: () =>
        __('Vil du kontaktes med job?'),
    cvOnlineTipBody: () =>
        __x('For at blive kontaktet af {sitename} og virksomheder, skal du sætte dit CV online.', { sitename }),
    setCvOnline: () =>
        __('Sæt CV online'),
    cvEducationTipHeaderTag: () =>
        __('Styrk dit CV'),
    cvEducationTipHeader: () =>
        __('Har du en uddannelse?'),
    cvEducationTipBody: () =>
        __('Med uddannelse og kurser på dit CV fremstår du bedre i CV-databasen.'),
    addEducation: () =>
        __('Tilføj uddannelse'),
    cvCreateTipLine1: () =>
        __('Vores bedste tip til din jobsøgning'),
    cvCreateTipLine2: () =>
        __('Opret CV og bliv kontaktet med job.'),
    close: () =>
        __('Luk'),
    numNew: (n) =>
        __nx('{n} ny', '{n} nye', n, { n: getNumberFormatter().format(n) }),
};
