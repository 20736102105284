<script setup>
import { ref, onBeforeUnmount } from 'vue';

import jQuery from 'jquery';

import i18n from './CvOnlineSelect.i18n.js';

import SvgIcon from 'JIX/components/SvgIcon.vue';

import 'icons/inline-menuarrow-down.svg';

defineProps({
    online: {
        type: Boolean,
        required: true,
    },
});

defineEmits(['update:online']);

const button = ref();
onBeforeUnmount(() => jQuery(button.value).dropdown('dispose'));
</script>

<template>
    <div class="cv-online-select dropdown">
        <button
            class="cv-online-select__btn"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
            ref="button"
        >
            {{ online ? i18n.statusOnline() : i18n.statusOffline() }}
            <SvgIcon
                name="inline-menuarrow-down"
                size="mini"
                role="presentation"
            />
        </button>
        <div class="dropdown-menu">
            <button type="button" class="dropdown-item" @click="$emit('update:online', true)">
                {{ i18n.statusOnline() }}
            </button>
            <button type="button" class="dropdown-item" @click="$emit('update:online', false)">
                {{ i18n.statusOffline() }}
            </button>
        </div>
    </div>
</template>
