<script>
const TYPES = ['primary', 'secondary', 'danger', 'info', 'warning', 'success', 'light'];
const SIZES = ['sm', 'md', 'lg'];
</script>

<script setup>
import { toRefs, computed } from 'vue';

import SvgIcon from 'JIX/components/SvgIcon.vue';

const props = defineProps({
    type: {
        type: String,
        required: true,
        validator: x => TYPES.includes(x),
    },
    iconName: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    size: {
        type: String,
        required: false,
        default: 'md',
        validator: x => SIZES.includes(x),
    },
    href: {
        type: String,
        required: false,
        default: null,
    },
    solid: {
        type: Boolean,
        required: false,
        default: false,
    },
    buttonType: {
        type: String,
        required: false,
        default: null,
    },
});

const { href, type, size, solid } = toRefs(props);

const tagName = computed(() => href.value ? 'a' : 'button');
const classes = computed(() => {
    const res = ['jix-icon-button', `jix-icon-button--${type.value}`];
    if (size.value !== 'md') {
        res.push(`jix-icon-button--${size.value}`);
    }
    if (solid.value) {
        res.push(`jix-icon-button--solid`);
    }
    return res;
});
</script>

<template>
    <Component
        :is="tagName"
        :title="label"
        :class="classes"
        :href="href"
        :type="buttonType"
    >
        <SvgIcon :name="iconName" role="presentation" />
    </Component>
</template>
