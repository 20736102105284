export const LoginResult = Object.freeze({
    LOGGED_IN:         "logged_in",
    ALREADY_LOGGED_IN: "already_logged_in",
    ABORTED:           "aborted",
    UNKNOWN:           "unknown",
});

function promisifyLoginCallback(res, { resolve }) {
    if (res.cancelled) {
        resolve(LoginResult.ABORTED);
    } else if (res.success) {
        resolve(LoginResult.LOGGED_IN);
    } else {
        resolve(LoginResult.ALREADY_LOGGED_IN);
    }
}

export function checkLogin(options = {}) {
    return new Promise((resolve, _reject) => {
        if (window?.JIX?.Login) {
            window.JIX.Login.verify_login('user', promisifyLoginCallback, { resolve, ...options });
        } else {
            resolve(LoginResult.UNKNOWN);
        }
    });
}
