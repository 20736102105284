import { createApp } from 'vue';

import { setCookie } from 'JIX/utils/cookies.js';

import UserDashboard from 'JIX/components/user/dashboard/UserDashboard.vue';

const stash = window.Stash['frontpage'];

const dashboardApp = createApp(UserDashboard, stash.dashboard);
dashboardApp.mount('#user-dashboard-app');

const newJobsearchSwitch = document.getElementById('new-jobsearch-switch');
if (newJobsearchSwitch) {
    newJobsearchSwitch.addEventListener('change', () => {
        setCookie('jix-new-jobsearch', newJobsearchSwitch.checked ? 1 : 0, { path: '/', samesite: 'Lax' });
        const url = location.href; // trick eslint
        location.href = url;
    });
}
