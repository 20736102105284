export const EditSections = Object.freeze({
    USER_INFO:     "USER_INFO",
    JOBHISTORY:    "JOBHISTORY",
    JOBWISHES:     "JOBWISHES",
    GEOGRAPHY:     "GEOGRAPHY",
    EDUHISTORY:    "EDUHISTORY",
    KEYWORDS:      "KEYWORDS",
    LANGUAGES:     "LANGUAGES",
    PRESENTATION:  "PRESENTATION",
    EMPLOYMENT:    "EMPLOYMENT",
    SALARY:        "SALARY",
    PERSONAL_INFO: "PERSONAL_INFO",
});

export const EditSectionOrder = Object.freeze([
    EditSections.USER_INFO,
    EditSections.JOBHISTORY,
    EditSections.JOBWISHES,
    EditSections.GEOGRAPHY,
    EditSections.EDUHISTORY,
    EditSections.KEYWORDS,
    EditSections.LANGUAGES,
    EditSections.PRESENTATION,
    EditSections.PERSONAL_INFO,
    EditSections.EMPLOYMENT,
    EditSections.SALARY,
]);

export const EditSectionFragments = Object.freeze({
    [EditSections.USER_INFO]:     'user',
    [EditSections.JOBHISTORY]:    'experience',
    [EditSections.JOBWISHES]:     'wishes',
    [EditSections.GEOGRAPHY]:     'geography',
    [EditSections.EDUHISTORY]:    'education',
    [EditSections.KEYWORDS]:      'keywords',
    [EditSections.LANGUAGES]:     'languages',
    [EditSections.PRESENTATION]:  'presentation',
    [EditSections.EMPLOYMENT]:    'employment',
    [EditSections.SALARY]:        'salary',
    [EditSections.PERSONAL_INFO]: 'personal',
});
