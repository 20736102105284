import { ref, computed, watchEffect } from 'vue';

export default function useTimeOfDay({ updateInterval = 60 * 1000 } = {}) {
    const hour = ref();
    const update = () => hour.value = (new Date()).getHours();
    update();

    if (updateInterval) {
        let intervalId;
        watchEffect((onCleanup) => {
            setInterval(update, updateInterval);
            onCleanup(() => { if (intervalId) { clearInterval(intervalId); } });
        });
    }

    return computed(() => {
        if (hour.value >= 6 && hour.value < 10) {
            return 'morning';
        } else if (hour.value >= 10 && hour.value < 13) {
            return 'day';
        } else if (hour.value >= 13 && hour.value < 18) {
            return 'afternoon';
        } else if (hour.value >= 18 && hour.value < 23) {
            return 'evening';
        } else {
            return 'night';
        }
    });
}
